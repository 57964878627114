import { FC, useRef, ReactNode } from 'react';
import { useFragment, graphql } from 'react-relay';
import classnames from 'classnames';

import { trackEvent } from 'dibs-tracking';
import { ItemTileCarousel } from 'dibs-search-product-tile/exports/ItemTile';
import FavoritesItemProvider from 'dibs-portfolio-folder/exports/FavoritesItemProvider';

import { FavoritesItem } from '../../components/global/FavoriteIcons/FavoritesItem';
import { SRC_SET_SIZES_TILE } from '../../constants/imageConstants';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { useSbSelector } from '../../reducers/useSbSelector';
import { useCurrency } from 'dibs-buyer-layout/exports/useCurrency';

import styles from './SharedItemTile.scss';

import { FavoritesProviderChildrenProps } from '../../components/global/FavoriteIcons/FavoritesProviderChildrenProps';
import { SharedItemTile_viewer$key } from './__generated__/SharedItemTile_viewer.graphql';
import { SharedItemTile_itemSearch$key } from './__generated__/SharedItemTile_itemSearch.graphql';
import { SharedItemTile_item$key } from './__generated__/SharedItemTile_item.graphql';
import { SharedItemTile_user$key } from './__generated__/SharedItemTile_user.graphql';

const itemSearchFragment = graphql`
    fragment SharedItemTile_itemSearch on ItemSearchQueryConnection {
        ...ItemTileCarousel_itemSearch
    }
`;
const itemFragment = graphql`
    fragment SharedItemTile_item on Item
    @argumentDefinitions(
        photosLimit: { type: "Int", defaultValue: 1 }
        fetchVideo: { type: "Boolean", defaultValue: false }
        pageDisplayEnum: { type: "PageDisplayEnum", defaultValue: searchAndBrowse }
        priceBookName: { type: "String" }
        showSeller: { type: "Boolean!" }
        isTrade: { type: "Boolean" }
    ) {
        ...ItemTileCarousel_item
            @arguments(
                showSeller: $showSeller
                isTrade: $isTrade
                fetchVideo: $fetchVideo
                pageDisplayEnum: $pageDisplayEnum
                priceBookName: $priceBookName
            )
        ...FavoritesItem_item
        ...FavoritesItemProvider_item
        serviceId
        productPhotos: photos(limit: $photosLimit) {
            smallPath
            versions {
                webPath
            }
        }
        isNewListing
    }
`;

const userFragment = graphql`
    fragment SharedItemTile_user on User {
        ...FavoritesItemProvider_user
    }
`;

const viewerFragment = graphql`
    fragment SharedItemTile_viewer on Viewer {
        ...ItemTileCarousel_viewer
            @arguments(
                fetchRegionalInfo: $fetchRegionalInfo
                userZipCode: $userZipCode
                userCountryCode: $userCountryCode
            )
    }
`;

type Props = {
    viewer: SharedItemTile_viewer$key | null | undefined;
    itemSearch: SharedItemTile_itemSearch$key | null | undefined;
    item: SharedItemTile_item$key | null;
    user: SharedItemTile_user$key | null | undefined;
    favorites?: FavoritesProviderChildrenProps;
    isSponsored?: boolean;
    index: number;
    isVisuallySimilarEnabled?: boolean;
    lazyLoadStartIndex?: number;
    imageLoadVerticalOffset?: number;
    onClick?: () => void;
    onContentLoaded?: () => void;
    showInternalSellerName?: boolean;
    showSellerName?: boolean;
    showCreatorName?: boolean;
    showMeasurements?: boolean;
    showStorefrontOnlyBadge?: boolean;
    measurementUnit?: string;
    beforeRender?: () => void;
    onContentVisible?: () => void;
    customClassName?: string;
    imageSize?: string;
    tileType?: string;
    showSwipeIndicator?: boolean;
    fetchFolderOnMouseOver?: boolean;
    showFavoritesTooltip?: boolean;
    additionalElements?: ReactNode;
    showPriceContainer?: boolean;
    showTitle?: boolean;
    showQuickView?: boolean;
    showMSKUSwatch?: boolean;
    showShippingBadge?: boolean;
    showNoReserveBadge?: boolean;
    urgencySignal?: string | null;
    hideRecentSoldPrice?: boolean;
};

export const SharedItemTile: FC<Props> = ({
    viewer: viewerRef,
    itemSearch: itemSearchRef,
    item: itemRef,
    user: userRef,
    isSponsored = false,
    favorites,
    index,
    isVisuallySimilarEnabled,
    lazyLoadStartIndex = 2,
    onClick,
    onContentLoaded,
    showInternalSellerName,
    showSellerName,
    showCreatorName,
    showMeasurements,
    showStorefrontOnlyBadge,
    measurementUnit,
    imageLoadVerticalOffset,
    beforeRender,
    onContentVisible,
    customClassName,
    imageSize,
    tileType,
    showSwipeIndicator,
    fetchFolderOnMouseOver,
    showFavoritesTooltip,
    additionalElements = null,
    showPriceContainer = true,
    showTitle,
    showQuickView,
    showMSKUSwatch,
    showShippingBadge,
    showNoReserveBadge,
    urgencySignal = null,
    hideRecentSoldPrice = false,
}) => {
    const item = useFragment(itemFragment, itemRef);
    const user = useFragment(userFragment, userRef);
    const viewer = useFragment(viewerFragment, viewerRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const tileElementRef = useRef(null);
    const itemId = item?.serviceId || '';
    const afterDisplay = useSbSelector(
        state =>
            state.relayVariables.variables.afterDisplay || state.relayVariables.variables.isClient
    );
    const { isMobile, isBot = false, isUrgencySignalsMw } = useServerVarsContext();
    const reduceMargins = isUrgencySignalsMw && isMobile;
    const { currency, showCurrency } = useCurrency();

    function onQuickViewDetailsClick(): void {
        trackEvent({
            category: 'ecommerce',
            label: itemId,
            action: 'product detail view - from seo quicklook',
        });
    }

    const sharedProps = {
        item,
        index,
        imageLoadVerticalOffset,
        onClick,
        onContentLoaded,
        imageSize,
        showTitle,
        showPriceContainer,
        showQuickView,
    };
    const photo = item?.productPhotos?.[0];
    if (!photo?.smallPath && !photo?.versions?.[0]?.webPath) {
        return null;
    }
    return (
        <div
            className={classnames(styles.tileWrapper, {
                [styles.tileWidth]: !customClassName,
                ...(customClassName ? { [customClassName]: true } : {}),
                [styles.completeInfo]: true,
            })}
            data-tn="item-tile-wrapper"
            data-tile-index={index}
            ref={tileElementRef}
        >
            {additionalElements}
            <FavoritesItemProvider
                item={item}
                user={user}
                applyMouseEvents={fetchFolderOnMouseOver}
                className={styles.favoritesItemProvider}
            >
                {({ isFolderVisible, ...favoritesItemProps }) => {
                    const applyIconWrapperClass = isMobile || !fetchFolderOnMouseOver;
                    const favoritesComponent = favorites ? (
                        <FavoritesItem
                            isNewListing={item?.isNewListing || false}
                            isSponsored={isSponsored}
                            wrapperClass={styles.favoritesWrapper}
                            heartIconWrapperClass={classnames(styles.heartIconWrapperSpacing, {
                                [styles.reducedIcon]: applyIconWrapperClass,
                            })}
                            folderIconWrapperClass={
                                applyIconWrapperClass ? styles.reducedIcon : undefined
                            }
                            item={item}
                            {...favorites}
                            fetchFolder={
                                favorites.fetchFolder &&
                                (!fetchFolderOnMouseOver || isFolderVisible || false)
                            }
                            showTooltip={showFavoritesTooltip}
                            {...favoritesItemProps}
                        />
                    ) : null;

                    return (
                        <ItemTileCarousel
                            {...sharedProps}
                            isSponsored={isSponsored}
                            useLoFiLazyLoader
                            itemsPerRow={lazyLoadStartIndex}
                            isMobile={isMobile}
                            beforeRender={beforeRender}
                            onContentVisible={onContentVisible}
                            srcSetSizes={SRC_SET_SIZES_TILE}
                            showQuickViewIcon={!!afterDisplay}
                            showSellerName={showSellerName}
                            showCreatorName={showCreatorName}
                            showInternalSellerName={showInternalSellerName}
                            showStorefrontOnlyBadge={showStorefrontOnlyBadge}
                            currency={currency}
                            showPrice={(afterDisplay && showCurrency) || isBot}
                            measurementUnit={measurementUnit}
                            onQuickViewDetailsClick={onQuickViewDetailsClick}
                            favoritesComponent={favoritesComponent}
                            showMeasurements={showMeasurements}
                            showBrowseSimilar={isVisuallySimilarEnabled && afterDisplay}
                            viewer={viewer}
                            tileType={tileType}
                            itemSearch={itemSearch}
                            showSwipeIndicator={showSwipeIndicator}
                            showMSKUSwatch={showMSKUSwatch}
                            showShippingBadge={showShippingBadge}
                            showNoReserveBadge={showNoReserveBadge}
                            hideRecentSoldPrice={hideRecentSoldPrice}
                            urgencySignal={urgencySignal}
                            reduceMargins={reduceMargins}
                        />
                    );
                }}
            </FavoritesItemProvider>
        </div>
    );
};
