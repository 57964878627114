import { pageTypeConstants } from './pageTypeConstants';

const ATTRIBUTE_CARATWEIGHT = 'carat-weight';
const ATTRIBUTE_MATERIAL = pageTypeConstants.MATERIAL;
const ATTRIBUTE_METAL = pageTypeConstants.METAL;
const ATTRIBUTE_ORIGIN = pageTypeConstants.ORIGIN;
const ATTRIBUTE_PERIOD = pageTypeConstants.PERIOD;
const ATTRIBUTE_ROOM = pageTypeConstants.ROOM;
const ATTRIBUTE_STONE = pageTypeConstants.STONE;
const ATTRIBUTE_STONECUT = 'stone-cut';
const ATTRIBUTE_STYLE = pageTypeConstants.STYLE;
const ATTRIBUTE_TECHNIQUE = pageTypeConstants.TECHNIQUE;

export const ATTRIBUTE_ITEMTYPE = 'item_type';
export const ATTRIBUTE_JEWELRYTYPE = 'jewelry-type';
export const ATTRIBUTE_COLOR = pageTypeConstants.COLOR;

export const ECOMMERCE_ATTRIBUTES = [
    ATTRIBUTE_CARATWEIGHT,
    ATTRIBUTE_COLOR,
    ATTRIBUTE_MATERIAL,
    ATTRIBUTE_METAL,
    ATTRIBUTE_ORIGIN,
    ATTRIBUTE_PERIOD,
    ATTRIBUTE_ROOM,
    ATTRIBUTE_STONE,
    ATTRIBUTE_STONECUT,
    ATTRIBUTE_STYLE,
    ATTRIBUTE_TECHNIQUE,
];
