import { graphql, readInlineData } from 'react-relay';
import SV from 'server-vars';
import {
    trackEcommerce,
    EcomProduct,
    trackingConstants,
    eventNameConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
import { hasInternalTrafficCookie } from 'dibs-cookie-jar';
import { LOCALE_US } from 'dibs-intl/exports/locales';
import { pageTypeConstants as pageTypes } from '../../../constants/pageTypeConstants';
import { ECOMMERCE_ATTRIBUTES } from '../../../constants/attributesConstants';
import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';
import { getPriceType } from '../shared/displayPriceTracking';

import {
    getIsFreeShipping,
    getLowestEligibleShippingCost,
    UserRegions,
} from 'dibs-search-product-tile/exports/shippingCostHelpers';

import { ecommerceTrackingProductData_item$key } from './__generated__/ecommerceTrackingProductData_item.graphql';
import { ecommerceTrackingUserProductData_item$key } from './__generated__/ecommerceTrackingUserProductData_item.graphql';
import { ecommerceTracking_item$key } from './__generated__/ecommerceTracking_item.graphql';
import { ecommerceTracking_itemSearch$key } from './__generated__/ecommerceTracking_itemSearch.graphql';
import {
    MOBILE_ITEMS_PER_ROW,
    RESP_ITEMS_PER_ROW,
} from '../../../finding/hooks/useMergedSbAndSponsoredItems';

const LIST_TRACKING_SEARCH = 'search results listing';
const LIST_TRACKING_BROWSE = 'browse page listing';
const LIST_TRACKING_ATTRIBUTE = 'attribute listing';
const LIST_TRACKING_STOREFRONT = 'storefront listing';
const LIST_TRACKING_BUY_PAGE = 'buy page';
const LIST_TRACKING_SEMANTIC = 'semantic result listing';

const { ECOM_PRODUCT_CLICK, ECOM_PRODUCT_IMPRESSION } = trackingConstants;
const { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST } = eventNameConstants;
const { STEP_SIMILAR_SOLD_ITEMS } = stepInteractionConstants;

const MAX_SPONSORED_ADS_PER_PAGE = 15;

export type UrgencySignal = { type: string; count: number; message: string } | null;

export type ItemData = {
    itemId: string;
    index: number;
    isSponsored?: boolean;
    urgencySignal: UrgencySignal;
};

const itemSearchFragment = graphql`
    fragment ecommerceTracking_itemSearch on ItemSearchQueryConnection @inline {
        pageType
        searchTerm
        searchRequestId
        sponsored {
            maxAvailable
            metadata {
                itemId
                impressionTrackerLink
                clickTrackerLink
            }
        }
    }
`;

const itemFragment = graphql`
    fragment ecommerceTracking_item on Item @inline {
        serviceId
        ...ecommerceTrackingProductData_item
        ...ecommerceTrackingUserProductData_item
    }
`;

const trackSponsoredItemsEvent = (link: string): void => {
    if (!hasInternalTrafficCookie(document.cookie, new URL(window.location.href))) {
        fetch(link, {
            method: 'GET',
            mode: 'no-cors',
            headers: { 'Content-Type': 'application/json' },
        });
    }
};

const getListType = (pageType: string, searchCorrectionTerm: string | null): string => {
    const { SEARCH, BROWSE, DEALER } = pageTypes;

    if (searchCorrectionTerm && pageType !== SEARCH) {
        return LIST_TRACKING_SEMANTIC;
    } else if (pageType === SEARCH) {
        return LIST_TRACKING_SEARCH;
    } else if (pageType === BROWSE) {
        return LIST_TRACKING_BROWSE;
    } else if (ECOMMERCE_ATTRIBUTES.includes(pageType)) {
        return LIST_TRACKING_ATTRIBUTE;
    } else if (pageType === DEALER) {
        return LIST_TRACKING_STOREFRONT;
    } else if (pageType === PAGE_TYPE.BUY) {
        return LIST_TRACKING_BUY_PAGE;
    } else if (pageType === PAGE_TYPE.SIMILAR_SOLD) {
        return STEP_SIMILAR_SOLD_ITEMS;
    } else {
        return `${pageType} listing`;
    }
};

const getResultsPosition = ({
    position,
    page,
    pageSize,
    maxSponsoredAvailable,
    isMobile,
}: {
    position: number;
    page: number;
    pageSize: number;
    maxSponsoredAvailable: number;
    isMobile?: boolean | null;
}): number => {
    // On the first page just return position
    if (page === 1) {
        return position;
    }

    const previousPage = page - 1;
    const previousPageItems = previousPage * pageSize;

    // If there are no sponsored items
    if (maxSponsoredAvailable === 0) {
        return previousPageItems + position;
    }

    // Check how many pages are fully packed with sponsored ads
    const numberOfPagesWithMaxSponsoredAds = Math.floor(
        maxSponsoredAvailable / MAX_SPONSORED_ADS_PER_PAGE
    );
    const actualAdsPerPage = isMobile ? MAX_SPONSORED_ADS_PER_PAGE - 1 : MAX_SPONSORED_ADS_PER_PAGE;

    // There are enough ads to take all ad slots
    if (numberOfPagesWithMaxSponsoredAds >= previousPage) {
        const sponsoredAds = actualAdsPerPage * previousPage;
        return previousPageItems + sponsoredAds + position;
    }

    // There were NOT enough ads to take all ad slots
    // corner case with (MAX_SPONSORED_ADS_PER_PAGE - 1) ads on last sponsored mobile page, which has all ads slots taken, handled below, too

    // Some pages are fully packed with sponsored ads
    const previousPagesWithMaxSponsoredAds = numberOfPagesWithMaxSponsoredAds * actualAdsPerPage;

    // There are some ads left, check how many rows are formed
    const leftSponsoredAds = maxSponsoredAvailable % MAX_SPONSORED_ADS_PER_PAGE;
    const adsPerRow = isMobile ? MOBILE_ITEMS_PER_ROW : RESP_ITEMS_PER_ROW;
    const fullRowsOfSponsoredAds = Math.floor(leftSponsoredAds / adsPerRow);

    const sponsoredAdsOnFullRows = fullRowsOfSponsoredAds * adsPerRow;

    return previousPageItems + previousPagesWithMaxSponsoredAds + sponsoredAdsOnFullRows + position;
};

const getProductData = ({
    index,
    isSponsored,
    item: itemRef,
    pageType,
    searchTerm,
    searchCorrectionTerm = '',
    /**
     * Ideally we would avoid using the global version of SV, keeping this for legacy code.
     */
    locale = SV.get('locale'),
    searchRequestId,
    page,
    pageSize,
    maxSponsoredAvailable,
    isMobile,
    urgencySignal,
}: {
    isSponsored: boolean;
    index: number;
    item: ecommerceTrackingProductData_item$key | null | undefined;
    pageType: string;
    searchTerm: string | null | undefined;
    searchCorrectionTerm: string | null;
    page: number;
    pageSize: number;
    maxSponsoredAvailable: number;
    locale?: string;
    searchRequestId?: string | null;
    isMobile?: boolean | null;
    urgencySignal: UrgencySignal;
}): EcomProduct => {
    const item = readInlineData(
        graphql`
            fragment ecommerceTrackingProductData_item on Item @inline {
                serviceId
                title
                seller {
                    serviceId
                }
                displayPriceTracking: displayPrice(
                    page: $pageDisplayEnum
                    isTrade: $isTrade
                    userCountryCode: $userCountryCode
                    priceBookName: $priceBookName
                ) {
                    convertedAmountList {
                        amount
                        currency
                    }
                    amountType
                    textType
                }
                categoryCode
                browseUrl
                contemporaryTrackingString
                isNewListing
                personalizationType
            }
        `,
        itemRef
    );
    const {
        serviceId,
        title,
        seller,
        displayPriceTracking,
        categoryCode,
        browseUrl,
        contemporaryTrackingString,
        isNewListing,
        personalizationType,
    } = item || {};

    const primaryDisplayPriceTracking = displayPriceTracking?.[0];
    const { convertedAmountList, amountType, textType } = primaryDisplayPriceTracking || {};

    const priceType = getPriceType({ amountType, textType });

    const usdPrice = (convertedAmountList || []).find(
        convertedAmount => convertedAmount?.currency === 'USD'
    );

    const isSearch = pageType === pageTypes.SEARCH;

    const position = index + 1; // 1-indexed

    return {
        id: serviceId,
        name: locale === LOCALE_US ? title : 'n/a',
        position,
        resultsPosition: getResultsPosition({
            position,
            page,
            pageSize,
            maxSponsoredAvailable,
            isMobile,
        }),
        brand: seller?.serviceId,
        category: browseUrl,
        categoryCode,
        dimension76: priceType,
        dimension83: contemporaryTrackingString,
        price: usdPrice?.amount || 0,
        list: getListType(pageType, searchCorrectionTerm),
        dimension141: isSponsored,
        dimension142: isNewListing || false,
        dimension143: personalizationType ?? null,
        ...((searchTerm && isSearch) || searchCorrectionTerm
            ? {
                  dimension98: searchCorrectionTerm || searchTerm,
                  searchCategoryClassification: isSearch && searchCorrectionTerm ? 'Y' : 'N',
              }
            : {}),
        ...(!isSponsored
            ? {
                  resultScoreTrackingUuid: searchRequestId || null,
              }
            : {}),
        ...(urgencySignal
            ? {
                  urgencySignal: `${urgencySignal.type.toLowerCase()} | ${urgencySignal.count}`,
              }
            : {}),
    };
};

function getUserProductData({
    item: itemRef,
    userRegions,
}: {
    item: ecommerceTrackingUserProductData_item$key | null | undefined;
    userRegions: UserRegions;
}): {
    dimension106: string;
    dimension120: number | undefined;
} {
    const item = readInlineData(
        graphql`
            fragment ecommerceTrackingUserProductData_item on Item @inline {
                ...shippingCostHelpers_item
            }
        `,
        itemRef
    );
    return {
        dimension106: getIsFreeShipping({ item, userRegions }) ? 'Y' : 'N',
        dimension120: getLowestEligibleShippingCost({ item, userRegions }),
    };
}

export const trackEcommerceProductImpressions = ({
    itemSearch: itemSearchRef,
    items: itemsRefArray,
    itemsData,
    pageType,
    page,
    pageSize,
    searchCorrectionTerm,
    userRegions,
    isMobile,
}: {
    itemSearch: ecommerceTracking_itemSearch$key | null | undefined;
    items: ReadonlyArray<ecommerceTracking_item$key> | null | undefined;
    itemsData: ItemData[];
    pageType?: string;
    page: number;
    pageSize: number;
    searchCorrectionTerm: string | null;
    userRegions: UserRegions;
    isMobile?: boolean | null;
}): void => {
    const itemSearch = readInlineData(itemSearchFragment, itemSearchRef);
    const items = (itemsRefArray || []).map(itemRef => readInlineData(itemFragment, itemRef));

    const { searchTerm, searchRequestId, sponsored } = itemSearch || {};
    const pageTypeValue = pageType || itemSearch?.pageType || pageTypes.BROWSE;

    const itemProductData = itemsData.map(({ index, isSponsored, urgencySignal }) => {
        const item = items?.[index] || null;
        const serviceId = item?.serviceId;
        const impressionTrackerLink =
            isSponsored &&
            sponsored?.metadata?.find(data => serviceId && serviceId === data?.itemId)
                ?.impressionTrackerLink;
        if (impressionTrackerLink) {
            trackSponsoredItemsEvent(impressionTrackerLink);
        }

        return {
            ...getUserProductData({
                item,
                userRegions,
            }),
            ...getProductData({
                isSponsored: !!impressionTrackerLink,
                pageType: pageTypeValue,
                searchTerm,
                searchCorrectionTerm,
                item,
                index,
                searchRequestId,
                page,
                pageSize,
                maxSponsoredAvailable: sponsored?.maxAvailable || 0,
                isMobile,
                urgencySignal,
            }),
        };
    });

    trackEcommerce({
        type: ECOM_PRODUCT_IMPRESSION,
        eventName: EVENT_VIEW_ITEM_LIST,
        products: itemProductData,
    });
};

export const trackEcommerceProductClick = ({
    itemSearch: itemSearchRef,
    items: itemsRef,
    index,
    page,
    pageSize,
    isSponsored,
    pageType,
    searchCorrectionTerm = '',
    userRegions,
    isMobile,
    urgencySignal,
}: {
    itemSearch: ecommerceTracking_itemSearch$key | null | undefined;
    items: ReadonlyArray<ecommerceTracking_item$key> | null | undefined;
    index: number;
    page: number;
    pageSize: number;
    isSponsored?: boolean;
    pageType?: string;
    searchCorrectionTerm: string | null;
    userRegions: UserRegions;
    isMobile?: boolean | null;
    urgencySignal: UrgencySignal;
}): void => {
    const itemSearch = readInlineData(itemSearchFragment, itemSearchRef);
    const item = readInlineData(itemFragment, itemsRef?.[index] || null);

    const { searchTerm, searchRequestId, sponsored } = itemSearch || {};
    const pageTypeValue = pageType || itemSearch?.pageType || pageTypes.BROWSE;

    const { serviceId } = item || {};
    const clickTrackerLink =
        isSponsored &&
        sponsored?.metadata?.find(data => serviceId && serviceId === data?.itemId)
            ?.clickTrackerLink;

    if (clickTrackerLink) {
        trackSponsoredItemsEvent(clickTrackerLink);
    }

    trackEcommerce({
        type: ECOM_PRODUCT_CLICK,
        eventName: EVENT_SELECT_ITEM,
        actionField: {
            list: getListType(pageTypeValue, searchCorrectionTerm),
            itemId: item?.serviceId,
        },
        products: [
            {
                ...getProductData({
                    isSponsored: !!clickTrackerLink,
                    item,
                    pageType: pageTypeValue,
                    index,
                    searchTerm,
                    searchCorrectionTerm,
                    searchRequestId,
                    page,
                    pageSize,
                    maxSponsoredAvailable: sponsored?.maxAvailable || 0,
                    isMobile,
                    urgencySignal,
                }),
                ...getUserProductData({
                    item,
                    userRegions,
                }),
            },
        ],
    });
};
